<template>
  <div class="page-login">
    <no-ssr>
      <component :is="layout" />
    </no-ssr>
  </div>
</template>

<script>

export default {
  name: 'PageLogin',
  data () {
    return {
      layout: ''
    }
  },
  mounted () {
    if (process.client) {
      const _this = this
      import('theme/components/Auth/Login').then((component) => {
        console.log(component)
        _this.layout = component.default
      })
      if (this.$store.state.user.current) {
        this.$router.push({ name: 'myProfile' })
      }
    }
  }
}
</script>
